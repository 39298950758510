/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes, { InferProps } from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import {
  Box,
  DefaultTheme,
  Heading,
  Icon,
  Text,
  makeStyles
} from 'groundkeeper-component-library';

import Link from './Link';

const useStyles = makeStyles((theme: DefaultTheme) => ({
  teaserItem: {
    // width: '33%',
    display: 'block',
    boxSizing: 'border-box',
    listStyle: 'none',
    '& h2': {
      fontSize: '1.0625rem',
      margin: '.5rem1 0 .4rem 0',
      paddingLeft: 0,
      borderLeft: 0
    },
    // @ts-ignore: TODO: Allow custom components in Theme
    ...(theme.components?.teaser?.root ? theme.components?.teaser?.root : {})
  },
  teaserImage: {
    background: `repeating-linear-gradient(
      135deg,
      #112B4A,
      #112B4A 10px,
      ${theme.color.primary.main} 10px,
      ${theme.color.primary.main} 40px
    );`,
    width: '100%',
    aspectRatio: '5/4',
    '&:hover': {
      cursor: 'pointer'
    },
    '& svg': {
      height: '6rem',
      width: '6rem'
    },
    // @ts-ignore: TODO: Allow custom components in Theme
    ...(theme.components?.teaser?.image ? theme.components?.teaser?.image : {})
  }
}));

export default function Teaser({
  title,
  text,
  icon,
  href,
  moreLink
}: InferProps<typeof Teaser.propTypes>) {
  const classes = useStyles();
  const router = useRouter();
  const intl = useIntl();
  const [linkHovered, setLinkHovered] = useState(false);

  function handleClickableAreaSmart(e) {
    if (!href) {
      console.warn(
        'Warning: `handleClickableAreaSmart` in `Teaser` Component has ' +
          'detected missing `href`. Area smart behavior is automatically ' +
          'disabled.'
      );
      return;
    }

    switch (e.type) {
      case 'click':
        router.push(href);
      case 'mouseenter':
        setLinkHovered(true);
        break;

      case 'mouseleave':
        setLinkHovered(false);
        break;
    }
  }

  return (
    <article className={classes.teaserItem}>
      <Box
        className={classes.teaserImage}
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={handleClickableAreaSmart}
        onMouseEnter={handleClickableAreaSmart}
        onMouseLeave={handleClickableAreaSmart}
      >
        {icon}
      </Box>
      <Heading
        onClick={handleClickableAreaSmart}
        onMouseEnter={handleClickableAreaSmart}
        onMouseLeave={handleClickableAreaSmart}
        type="h2"
      >
        {title}
      </Heading>
      <Box display="inline">
        <Text data-testid="teaser/text">
          {text}
          <Link
            style={{
              display: 'block',
              textDecoration: linkHovered ? 'underline' : null
            }}
            href={href}
            showArrow
          >
            {moreLink
              ? moreLink
              : intl.formatMessage({
                  id: 'more',
                  defaultMessage: 'More'
                })}
          </Link>
        </Text>
      </Box>
    </article>
  );
}

Teaser.propTypes = {
  title: PropTypes.node.isRequired,
  text: PropTypes.node,
  icon: PropTypes.node,
  href: PropTypes.string,
  moreLink: PropTypes.string
};

Teaser.defaultProps = {
  moreLink: undefined
};
